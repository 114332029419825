// @ts-nocheck
import {Column} from 'react-table'
import {UserCustomHeader} from '../../apps/user-management/users-list/table/columns/UserCustomHeader'
import {ProductLedger, deleteProductLedger} from './api'
import {KTIcon} from '../../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import {toast} from 'react-hot-toast'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useLayout} from '../../../../_metronic/layout/core'
const getLedgerColumns = (setCreateLedger, setEditLedger): ReadonlyArray<Column<ProductLedger>> => [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Ledger Code'
        className='min-w-125px ps-4 text-center'
      />
    ),
    id: 'ledgerCode',
    accessor: 'ledgerCode',
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Parent Ledger'
        className='min-w-125px text-center'
      />
    ),
    accessor: 'parentLedger',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Sub Ledger' className='min-w-125px text-center' />
    ),
    accessor: 'ledger',
    Cell: ({...props}) => <div>{props.data[props.row.index].subLedger ?? '-'} </div>,
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title='Parent Group'
        className='min-w-125px text-center'
      />
    ),
    accessor: 'parentGroup',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Sub Group' className='min-w-125px text-center' />
    ),
    accessor: 'subGroup',
    Cell: ({...props}) => (
      <div>
        {props.data[props.row.index].subGroup === null ? '-' : props.data[props.row.index].subGroup}{' '}
      </div>
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='min-w-125px text-center' />
    ),
    accessor: 'actions',
    Cell: ({...props}) => {
      const {company, financialYear} = useLayout()
      const navigate = useNavigate()
      console.log(props)
      const [showDeleteModal, setShowDeleteModal] = useState(false)
      const [showLedgerDetailsModal, setShowLedgerDetailsModal] = useState(false)

      const handleDelete = async () => {
        deleteProductLedger(company.value, props.data[props.row.index].ledgerId)
          .then((res) => res?.detail && toast.success(res.detail))
          .catch((err) => err && toast.error(err))
          .finally(() => setShowDeleteModal(false))
      }

      const handleEdit = () => {
        setCreateLedger(true)
        setEditLedger(props.data[props.row.index])
      }

      const ledger = props.data[props.row.index]

      return (
        <>
          <div className='d-flex justify-content-center flex-shrink-0'>
            <div
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title='View Ledger Details'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => setShowLedgerDetailsModal(true)}
            >
              <KTIcon iconName='switch' className='fs-3' />
            </div>
            <div
              onClick={handleEdit}
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title='Edit'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            >
              <KTIcon iconName='pencil' className='fs-3' />
            </div>
            <div
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title='Delete'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={() => setShowDeleteModal(true)}
            >
              <KTIcon iconName='trash' className='fs-3' />
            </div>
            <div
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-dismiss-='click'
              title='View Transactions'
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={() => {
                let url = `/transactions/trial-balance/ledger?parent_ledger=${
                  props.data[props.row.index].parentLedger
                }&parent_group=${props.data[props.row.index].parentGroup}`
                props.data[props.row.index].subGroup !== null &&
                  (url = url + `&sub_group=${props.data[props.row.index].subGroup}`)
                navigate(url)
              }}
            >
              <KTIcon iconName='arrow-right' className='fs-2' />
            </div>
          </div>

          <Modal
            className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
            id='disable_financial_year'
            role='dialog'
            data-backdrop='false'
            aria-hidden='true'
            tabIndex='-1'
            show={showDeleteModal}
            animation={false}
          >
            <div className='modal-content'>
              {/*begin::Header*/}
              <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
                <h5 className='fw-bold m-0'>Delete Ledger</h5>
                <div className='d-flex ms-2'>
                  {/*begin::Close*/}
                  <div
                    className='btn btn-icon btn-sm btn-light-primary ms-2'
                    data-bs-dismiss='modal'
                    onClick={() => setShowDeleteModal(false)}
                  >
                    <KTIcon className='fs-1' iconName='cross' />
                  </div>
                  {/*end::Close*/}
                </div>
              </div>
              {/*end::Header*/}
              <div className='text-center py-5 px-5'>
                Are you sure you want to delete this Ledger? Please note the Ledger can only be
                deleted if there are no transactions under this Ledger.
              </div>
              <div className='text-center pt-5 pb-5'>
                <button
                  type='reset'
                  onClick={() => setShowDeleteModal(false)}
                  className='btn btn-light me-3'
                  data-kt-users-modal-action='cancel'
                >
                  Cancel
                </button>

                <button
                  type='submit'
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                  onClick={handleDelete}
                >
                  Proceed
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            size='lg'
            className='modal-sticky modal-sticky-lg '
            id='ledger_details'
            data-backdrop='false'
            aria-hidden='true'
            show={showLedgerDetailsModal}
            animation={false}
          >
            <div className='modal-content'>
              {/*begin::Header*/}
              <div className='d-flex align-items-center justify-content-between py-5 ps-8 pe-5 border-bottom'>
                <h5 className='fw-bold m-0'>Ledger Details</h5>
                <div className='d-flex ms-2'>
                  {/*begin::Close*/}
                  <div
                    className='btn btn-icon btn-sm btn-light-primary ms-2'
                    data-bs-dismiss='modal'
                    onClick={() => setShowLedgerDetailsModal(false)}
                  >
                    <KTIcon className='fs-1' iconName='cross' />
                  </div>
                  {/*end::Close*/}
                </div>
              </div>
              {/*end::Header*/}
              <div className='d-flex justify-content-between'>
                <div className='text-center py-5 px-5 w-50'>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Ledger Code</label>

                    <div className='col-lg-8'>
                      <span
                        className={`fw-bolder fs-6 text-${ledger.ledgerCode ? 'dark' : 'danger'}`}
                      >
                        {ledger.ledgerCode ?? 'NA'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Parent Ledger</label>

                    <div className='col-lg-8'>
                      <span
                        className={`fw-bolder fs-6 text-${ledger.parentLedger ? 'dark' : 'danger'}`}
                      >
                        {ledger.parentLedger}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Sub Ledger</label>

                    <div className='col-lg-8'>
                      <span
                        className={`fw-bolder fs-6 text-${ledger.subLedger ? 'dark' : 'danger'}`}
                      >
                        {ledger.subLedger ?? 'NA'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Parent Group</label>

                    <div className='col-lg-8'>
                      <span
                        className={`fw-bolder fs-6 text-${ledger.parentGroup ? 'dark' : 'danger'}`}
                      >
                        {ledger.parentGroup ?? 'NA'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Sub Group</label>

                    <div className='col-lg-8'>
                      <span
                        className={`fw-bolder fs-6 text-${ledger.subGroup ? 'dark' : 'danger'}`}
                      >
                        {ledger.subGroup ?? 'NA'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Transaction Type</label>

                    <div className='col-lg-8'>
                      <span
                        className={`fw-bolder fs-6 text-${
                          ledger.transactionType ? 'dark' : 'danger'
                        }`}
                      >
                        {ledger.transactionType ?? 'NA'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Opening Balance</label>

                    <div className='col-lg-8'>
                      <span className={`fw-bolder fs-6 text-dark`}>{ledger.openingBalance}</span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>As On Date</label>

                    <div className='col-lg-8'>
                      <span className={`fw-bolder fs-6 text-dark`}>
                        {new Date(ledger.asOnDate).toDateString().slice(4)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='text-center py-5 px-5 w-50'>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Currency</label>

                    <div className='col-lg-8'>
                      <span className={`fw-bolder fs-6 text-dark`}>{ledger.currency ?? 'NA'}</span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Account Number</label>

                    <div className='col-lg-8'>
                      <span
                        className={`fw-bolder fs-6 text-${
                          ledger.account_number ? 'dark' : 'danger'
                        }`}
                      >
                        {ledger.account_number ?? 'NA'}
                      </span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Branch Name</label>

                    <div className='col-lg-8'>
                      <span
                        className={`fw-bolder fs-6 text-${ledger.branch_name ? 'dark' : 'danger'}`}
                      >
                        {ledger.branch_name ?? 'NA'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Address</label>

                    <div className='col-lg-8'>
                      <span className={`fw-bolder fs-6 text-${ledger.address ? 'dark' : 'danger'}`}>
                        {ledger.address ?? 'NA'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>State</label>

                    <div className='col-lg-8'>
                      <span className={`fw-bolder fs-6 text-${ledger.state ? 'dark' : 'danger'}`}>
                        {ledger.state ?? 'NA'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Country</label>

                    <div className='col-lg-8'>
                      <span className={`fw-bolder fs-6 text-${ledger.country ? 'dark' : 'danger'}`}>
                        {ledger.country ?? 'NA'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Zip Code</label>

                    <div className='col-lg-8'>
                      <span className={`fw-bolder fs-6 text-${ledger.zipcode ? 'dark' : 'danger'}`}>
                        {ledger.zipcode ?? 'NA'}
                      </span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>IFSC Code</label>

                    <div className='col-lg-8'>
                      <span
                        className={`fw-bolder fs-6 text-${ledger.ifsc_code ? 'dark' : 'danger'}`}
                      >
                        {ledger.ifsc_code ?? 'NA'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )
    },
  },
]

export {getLedgerColumns}
