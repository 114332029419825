import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout, OptionType} from '../../core'

import {useCallback, useEffect, useState} from 'react'
import Select from 'react-select'
import {CompanyListResp, getCompaniesList} from './api'
import {cssClasses} from 'nouislider'

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'

const Navbar = () => {
  const {
    company,
    setCompany,
    financialYear,
    setFinancialYear,
    trigger,
    companyOptions,
    setCompanyOptions,
  } = useLayout()

  const [companyYearObj, setCompanyYearObj] = useState<CompanyListResp>()
  // const [companyOptions, setCompanyOptions] = useState<OptionType[]>([])
  const [yearOptions, setYearOptions] = useState<OptionType[]>([])
  const getCompanyList = useCallback(async () => {
    const res = await getCompaniesList()
    console.log(res)
    setCompanyYearObj(res)

    const companies = Object.keys(res).map((cin) => ({
      label: res[cin].companyName,
      value: cin,
    }))
    console.log(companyOptions.length)
    console.log(companies.length)
    if (!companyOptions.length && companies.length > 0) {
      setCompanyOptions(companies)

      // Only set default company if there's no existing selection
      if (!company.value) {
        // If no valid saved company, set the first company as default
        setCompany(companies[2])
      }
    } else if (companyOptions.length > 0 && companies.length > companyOptions.length) {
      setCompanyOptions(companies)
      if (!company.value) {
        // If no valid saved company, set the first company as default
        setCompany(companies[2])
      } else setCompany(companies[companyOptions.length])
    } else {
      setCompany({
        label: '',
        value: '',
      })
    }
  }, [])

  useEffect(() => {
    getCompanyList()
  }, [getCompanyList, trigger])

  useEffect(() => {
    if (company && company?.value) {
      const years = companyYearObj?.[company?.value]?.fyList.map((yr) => ({
        label: yr,
        value: yr,
      }))
      years && setYearOptions(() => years)
      years && setFinancialYear(() => years[0])
    } else {
      setFinancialYear({
        value: '',
        label: '',
      })
    }
  }, [company, companyYearObj, setFinancialYear])

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className='app-navbar-item' style={{marginRight: '20px'}}>
        <div
          style={{
            width: '38px',
            height: '38px',
            background: '#F1F1F2',
            borderRadius: '6px 0px 0px 6px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <KTIcon iconName='briefcase' className='fs-2' />
          {/* <i className='fa-solid fa-briefcase'></i> */}
        </div>
        <Select
          value={company}
          isDisabled={!company?.value}
          onChange={(option) => setCompany(option as OptionType)}
          options={companyOptions}
          isSearchable={true}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              marginLeft: '2px',
              width: '200px',
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              width: '250px',
            }),
          }}
          components={{IndicatorSeparator: () => null}}
        />
      </div>

      <div className='app-navbar-item'>
        <div
          style={{
            width: '38px',
            height: '38px',
            background: '#F1F1F2',
            borderRadius: '6px 0px 0px 6px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <i className='fa-solid fa-calendar-days' />
        </div>
        <Select
          value={financialYear}
          onChange={(option) => setFinancialYear(option as OptionType)}
          isDisabled={!company?.value}
          options={yearOptions}
          isSearchable={false}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              marginLeft: '2px',
              width: '130px',
            }),
          }}
          components={{IndicatorSeparator: () => null}}
        />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/Default.jpg')} alt='' />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Navbar}
