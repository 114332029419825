import {Transaction} from './ViewTransactionsAPI'
import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface AccountingDetails {
  byLedger: ByLedger
  toLedger: ToLedger
  voucherTypeMap: VoucherTypeMap
  voucherCurrency: string[]
  ledgerCurrentBalanceMapping: Record<string, Record<string, string>>
  reportingCurrency: string
  ledgerMapping: any
}

export interface ByLedger {
  journal: Record<string, string[]>
  receipt: Record<string, string[]>
  payment: Record<string, string[]>
  contra: Record<string, string[]>
  sales: Record<string, string[]>
  purchases: Record<string, string[]>
  debitnote: Record<string, string[]>
  creditnote: Record<string, string[]>
}

export interface ToLedger {
  journal: Record<string, string[]>
  receipt: Record<string, string[]>
  payment: Record<string, string[]>
  contra: Record<string, string[]>
  sales: Record<string, string[]>
  purchases: Record<string, string[]>
  debitnote: Record<string, string[]>
  creditnote: Record<string, string[]>
}

export interface VoucherTypeMap {
  journal: string[]
  receipt: string[]
  payment: string[]
  contra: string[]
  sales: string[]
  purchase: string[]
  debitnote: string[]
  creditnote: string[]
}

export const getAccountingDetails = async (
  companyId: string,
  fy: string
): Promise<AccountingDetails | undefined> => {
  return await axios
    .get(`${API_URL}/accounting/transaction/data/${companyId}/${fy}`)
    .then((response: AxiosResponse) => {
      const {
        by_ledger,
        to_ledger,
        voucher_map,
        transacting_currency,
        reporting_currency,
        ledger_current_balance,
        ledger_id,
      } = response.data

      return {
        byLedger: by_ledger,
        toLedger: to_ledger,
        voucherTypeMap: voucher_map,
        voucherCurrency: transacting_currency,
        reportingCurrency: reporting_currency,
        ledgerCurrentBalanceMapping: ledger_current_balance,
        ledgerMapping: ledger_id,
      }
    })
}

export type IndividualTransaction = {
  transactionType: 'debit' | 'credit'
  parentLedger: string
  subLedger: string | null
  ledgerCode?: string | null
  amount: number
  transactionAmount?: number
}
export type TransactionItem = {
  transaction_item_id?: number
  transaction_id?: number
  transaction_type: 'debit' | 'credit'
  ledger_id?: number
  transaction_amount?: number
}

export interface CreateTransactionBody {
  transaction: CreateReferenceBody
  dbId?: number
  companyId?: string
  fyName?: string
  voucherType?: string
  voucherName?: string | null
  voucherDate?: string
  voucherCurrency?: string
  reportingCurrency?: string
  transactingCurrency?: string
  transactionDetails?: IndividualTransaction[]
  narration?: string
  previousVoucherType?: string
  fetchedRate?: number
  applicableRate?: number
  updateExchangeRate?: boolean
  voucherNumber?: number
  totalAmount?: number
  invoiceNumber?: string
  invoiceAmount?: number
  invoiceDescription?: string
  invoiceDetails?: SetOffInvoiceResp[]
  exchangeMultiplier?: string
  isNAReferenceAmount?: number
}
export interface CreateReferenceBody {
  transaction_id?: number
  company_id?: string
  fy_name?: string
  voucher_id?: number
  voucher_number?: number
  currency_code: string
  voucher_date?: string
  is_na_reference: boolean
  na_reference_amount: number
  reporting_currency?: string
  transaction_item?: TransactionItem[]
  narration?: string
  exchange_multiplier?: string
  total_amount?: number

  // invoiceDetails?: SetOffInvoiceResp[]

  // isNAReferenceAmount?: number
}
export type CreateReferenceResp = {
  show_options: boolean
  transaction_item_id: number
  transaction_id: number
  ledger_id: number
  ledger_code: string
  parent_ledger: string
  sub_ledger: string
  transaction_type: string
  transaction_amount: number
  invoice_number: number
  against_advance: {
    advance_id: number
    advance_number: number
    advance_date: string
    amount: number
    transaction_type: string
    settled_amount: number
    set_off_amount: number
    voucher_type: string
    voucher_number: number
    description: string
  }
  advance_number: number
  against_invoice: {
    invoice_id: number
    invoice_number: number
    invoice_date: string
    due_date: string
    amount: number
    transaction_type: string
    settled_amount: number
    set_off_amount: number
    voucher_type: string
    voucher_number: number
    description: string
  }
  is_na_reference: boolean
  reference_amount: number
  message: string
}

export const createTransaction = async (
  body: CreateTransactionBody
): Promise<{detail: string} | undefined> => {
  try {
    const response = await axios.post(`${API_URL}/accounting/transaction/add/transaction`, body)
    return response.data
  } catch (error: any) {
    if (error.response) {
      throw error.response.data
    }
  }
}

type ExchangeRateResp = {
  companyId: number
  currencyCode: string
  exchangeRate: number
  // exchangeDate: string
  asOnDate: string
  newExchangeRate?: string
}

export const getExchangeRate = async (
  companyId: string,
  fy: string,
  ref_date: string,
  currency_code: string
): Promise<ExchangeRateResp | undefined> => {
  return await axios
    .get(
      `${API_URL}/accounting/transaction/data/exchange-rate/${companyId}/${fy}?voucher_date=${ref_date}&currency_code=${currency_code}`
    )
    .then((response: AxiosResponse) => ({
      companyId: Number(companyId),
      currencyCode: response.data.currency_code,
      asOnDate: response.data.exchange_date,
      exchangeRate: response.data.exchange_rate,
      newExchangeRate: response.data.new_exchange_rate,
    }))
}

export interface SetOffInvoiceResp {
  invoiceNumber: string
  invoiceAmount: number
  settledInvoiceAmount: number
  setOffAmount: number
  voucherType: string
  voucherNumber: number
}

export const getSetOffInvoiceDetails = async (
  companyId: string,
  fy: string,
  parentLedger: string,
  voucher_date: string,
  subLedger?: string
): Promise<{invoiceDetails: SetOffInvoiceResp[]} | undefined> => {
  let url = `${API_URL}/accounting/transactions/invoice/details/${companyId}/${fy}?parent_ledger=${parentLedger}&voucher_date=${voucher_date}`
  if (subLedger) url += `&sub_ledger=${subLedger}`
  url = encodeURI(url)
  return await axios
    .get(url)
    .then((response: AxiosResponse<{invoiceDetails: SetOffInvoiceResp[]}>) => response.data)
    .then((response: {invoiceDetails: SetOffInvoiceResp[]}) => response)
}
export const createNewTransaction = async (body: CreateReferenceBody) => {
  try {
    const response = await axios.post(
      `${API_URL}/accounting/transaction/add/create-reference`,
      body
    )
    return response.data
  } catch (error: any) {
    if (error.response) {
      throw error.response.data
    }
  }
}
