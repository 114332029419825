/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {getCompanyById} from '../core/_requests'
import {Company} from '../core/_models'
import {KTIcon, toAbsoluteUrl} from '../../../../../../_metronic/helpers'

export const CompanyDetail = () => {
  const {id} = useParams()

  const [companyDetails, setCompanyDetails] = useState<any>()

  const getCompanyDetails = async (id: string) => {
    const res = await getCompanyById(id)
    setCompanyDetails(res)
  }

  useEffect(() => {
    id && getCompanyDetails(id)
  }, [id])

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            {/* <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic' />
                <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
              </div>
            </div> */}

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {companyDetails?.company_name}
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                      {companyDetails?.company_id}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      {companyDetails?.state}, {companyDetails?.country}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      {companyDetails?.email}
                    </a>
                  </div>
                </div>
              </div>

              {/* <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>13,490</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Transactions</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>3,45,60,900₹</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Total Assets</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' />
                        <div className='fs-2 fw-bolder'>4,50,67,900₹</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Total Liabilities</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-2 fw-bolder'>20,45,000₹</div>
                      </div>

                      <div className='fw-bold fs-6 text-gray-400'>Current P/L</div>
                    </div>
                  </div>
                </div>

              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Company Details</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Company Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{companyDetails?.company_name}</span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>CIN</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6 text-dark'>{companyDetails?.cin}</span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>
              Contact Phone
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{companyDetails?.phone_number}</span>

              <span className='badge badge-success'>Verified</span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Company Site</label>

            <div className='col-lg-8'>
              <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                {companyDetails?.website_url}
              </a>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Address</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{companyDetails?.address}</span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Zip Code</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{companyDetails?.postal_code}</span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>GST Number</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-dark'>{companyDetails?.gst_in || '-'}</span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Incorporation Date</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-dark'>{companyDetails?.incorporation_date}</span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Financial Start Date</label>

            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-dark'>{companyDetails?.fy_start_date}</span>
            </div>
          </div>

          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-muted'>Reporting Currency</label>

            <div className='col-lg-8'>
              <span className='badge badge-primary'>{companyDetails?.reporting_currency}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
