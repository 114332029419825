import {FC, createContext, useContext, useState, useEffect} from 'react'
import {DefaultConfig} from './_LayoutConfig'
import {
  setLayoutIntoLocalStorage,
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './_LayoutSetup'
import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
  LayoutType,
  ToolbarType,
} from './_Models'
import {WithChildren} from '../../helpers'

export type OptionType = {
  value: string
  label: string
}
export interface LayoutContextModel {
  config: ILayout
  classes: ILayoutCSSClasses
  attributes: ILayoutHTMLAttributes
  cssVariables: ILayoutCSSVariables
  setLayout: (config: LayoutSetup) => void
  setLayoutType: (layoutType: LayoutType) => void
  setToolbarType: (toolbarType: ToolbarType) => void
  company: OptionType
  setCompany: React.Dispatch<React.SetStateAction<OptionType>>
  financialYear: OptionType
  setFinancialYear: React.Dispatch<React.SetStateAction<OptionType>>
  trigger: number
  setTrigger: React.Dispatch<React.SetStateAction<number>>
  companyOptions: OptionType[]
  setCompanyOptions: React.Dispatch<React.SetStateAction<OptionType[]>>
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => {},
  setLayoutType: (layoutType: LayoutType) => {},
  setToolbarType: (toolbarType: ToolbarType) => {},
  company: {label: '', value: ''},
  financialYear: {label: '', value: ''},
  setCompany: () => {},
  setFinancialYear: () => {},
  trigger: 0,
  setTrigger: () => {},
  companyOptions: [],
  setCompanyOptions: () => {},
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

const LayoutProvider: FC<WithChildren> = ({children}) => {
  const [config, setConfig] = useState(LayoutSetup.config)
  const [classes, setClasses] = useState(LayoutSetup.classes)
  const [attributes, setAttributes] = useState(LayoutSetup.attributes)
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)
  const [companyOptions, setCompanyOptions] = useState<OptionType[]>([])
  const [trigger, setTrigger] = useState(0)

  const [company, setCompany] = useState<OptionType>(() => {
    const savedCompany = localStorage.getItem('company')
    if (savedCompany) {
      try {
        const parsed = JSON.parse(savedCompany)
        if (parsed && typeof parsed.value === 'string' && typeof parsed.label === 'string') {
          return parsed
        }
      } catch (e) {
        console.error('Error parsing saved company:', e)
      }
    }
    return {label: '', value: ''}
  })

  const [financialYear, setFinancialYear] = useState<OptionType>({
    label: localStorage.getItem('financialYear') || '',
    value: localStorage.getItem('financialYear') || '',
  })
  console.log(company)
  console.log(financialYear)

  useEffect(() => {
    if (company.value) localStorage.setItem('company', JSON.stringify(company))
  }, [company])

  useEffect(() => {
    if (financialYear.value) localStorage.setItem('financialYear', financialYear?.value)
  }, [financialYear])

  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    const updatedConfig = LayoutSetup.updatePartialConfig(_themeConfig)
    setConfig(Object.assign({}, updatedConfig))
    setClasses(LayoutSetup.classes)
    setAttributes(LayoutSetup.attributes)
    setCSSVariables(LayoutSetup.cssVariables)
    setTimeout(() => {
      disableSplashScreen()
    }, 500)
  }

  const setToolbarType = (toolbarType: ToolbarType) => {
    const updatedConfig = {...config}
    if (updatedConfig.app?.toolbar) {
      updatedConfig.app.toolbar.layout = toolbarType
    }

    setLayoutIntoLocalStorage(updatedConfig)
    window.location.reload()
  }

  const setLayoutType = (layoutType: LayoutType) => {
    const updatedLayout = {...config, layoutType}
    setLayoutIntoLocalStorage(updatedLayout)
    window.location.reload()
  }

  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    setLayout,
    setLayoutType,
    setToolbarType,
    company,
    setCompany,
    financialYear,
    setFinancialYear,
    trigger,
    setTrigger,
    companyOptions,
    setCompanyOptions,
  }

  useEffect(() => {
    disableSplashScreen()
  }, [])

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export {LayoutContext, LayoutProvider}

export function useLayout() {
  return useContext(LayoutContext)
}
