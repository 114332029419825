// import {updateExchangeRates} from './api'
import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface Currency {
  dbId?: number
  currencyCode: string
  formalName: string
  currencySymbol: string
  exchangeDate: string
  exchangeRate: string
}

export const getCurrency = async (companyId: string): Promise<Currency[] | undefined> => {
  return await axios
    .get(`${API_URL}/product-master/currency/${companyId}`)
    .then((response: AxiosResponse<any>) =>
      response.data?.map((v: any) => {
        return {
          currencyCode: v.currency_code,
          currencySymbol: v.currency_symbol,
          formalName: v.formal_name,
          companyId,
        }
      })
    )
}

export interface AllCurrencyResp {
  data: CurrencyDetails[]
}

export interface CurrencyDetails {
  code: string
  country: string
  currency: string
  symbol: string
}

export const getAllCurrencyOptions = async (): Promise<CurrencyDetails[] | undefined> => {
  return await axios
    .get(`${API_URL}/utils/currency`)
    .then((response: AxiosResponse<AllCurrencyResp>) => response.data)
    .then((response: AllCurrencyResp) => response.data)
}

interface CreateCurrencyBody {
  company_id: number
  currency_code: string
  formal_name: string
  currency_symbol: string
  decimal: number
}

export const createCurrency = async (
  body: CreateCurrencyBody
): Promise<{detail: string} | undefined> => {
  return await axios
    .post(`${API_URL}/product-master/currency/add`, body)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}
export const updateCurrency = async (
  body: CreateCurrencyBody
): Promise<{detail: string} | undefined> => {
  return await axios
    .post(`${API_URL}/product-master/currency/update`, body)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

export const deleteCurrency = async (currency: string): Promise<{detail: string} | undefined> => {
  return await axios
    .delete(`${API_URL}/product-master/currency/${currency}`)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

export interface ExchangeRatesResp {
  exchangeRatesData: {
    currencyCode: string
    exchangeRate: number | null
    exchangeDate: string | null
    newExchangeRate: number | null
  }[]
}

export const getExchangeRates = async (
  companyId: string,
  fy: string,
  exchangeDate: string
): Promise<ExchangeRatesResp | undefined> => {
  return await axios
    .get(`${API_URL}/product-master/exchange-rate/${companyId}/${fy}/${exchangeDate}`)
    .then((response: AxiosResponse<any>) =>
      response.data.map((v: any) => {
        return {
          companyId: v.company_id,
          currencyCode: v.currency_code,
          exchangeRate: v.exchange_rate,
          exchangeDate: v.exchange_date,
          newExchangeRate: v.new_exchange_rate,
        }
      })
    )
}

export interface UpdateExchangeRatesParams {
  updateExchangeRatesParams: {
    companyId: string
    exchangeRateDate: string
    currencyCode: string
    previousExchangeRate: number | null
    currentExchangeRate: number | null
  }[]
}

export const updateExchangeRates = async (
  exchangeDate: string,
  params: UpdateExchangeRatesParams
): Promise<{detail: string} | undefined> => {
  return await axios
    .post(
      `${API_URL}/product-master/exchange-rate/save?exchange_date=${exchangeDate}`,
      params.updateExchangeRatesParams.map((_) => ({
        company_id: _.companyId,
        currency_code: _.currencyCode,
        exchange_date: _.exchangeRateDate,
        exchange_rate: _.previousExchangeRate,
        new_exchange_rate: _.currentExchangeRate,
      }))
    )
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

export const downloadPdf = async (
  records: Currency[],
  companyId: string,
  fyYear: string
): Promise<any | undefined> => {
  return await axios.post<any>(`${API_URL}/pdfDownload`, {
    records,
    companyId,
    fyYear,
  })
}
