import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {KTCard, KTCardBody, useDebounce} from '../../../../_metronic/helpers'
import {Loader} from '../loader'
import {getLedgerColumns} from './columns'
import {ProductLedger, getProductLedgers, downloadPdf} from './api'
import clsx from 'clsx'
import {useLayout} from '../../../../_metronic/layout/core'
import {KTIcon} from '../../../../_metronic/helpers'
import {CreateEditLedger} from './CreateEditLedger'
import {Modal} from 'react-bootstrap'
import toast from 'react-hot-toast'
import jsPDF from 'jspdf'

const styles = {
  borderRadius: '0.475rem',
  boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
  backgroundColor: '#fff',
  color: '#7e8299',
  fontWeight: '500',
  margin: '0',
  width: 'auto',
  padding: '1rem 2rem',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 4rem)',
}

export const Ledger = () => {
  const {company, financialYear} = useLayout()

  const tableRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [createLedger, setCreateLedger] = useState(false)
  const [data, setData] = useState<ProductLedger[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [editLedger, setEditLedger] = useState<ProductLedger | undefined>(undefined)

  const columns = useMemo(() => getLedgerColumns(setCreateLedger, setEditLedger), [])

  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const filteredData: ProductLedger[] = useMemo(() => {
    if (debouncedSearchTerm?.length) {
      return data.filter(
        (d) =>
          d.ledgerCode.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d.parentLedger.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d.subLedger?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d.parentGroup.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d.subGroup?.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      )
    }
    return []
  }, [debouncedSearchTerm, data])
  console.log(data)
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: debouncedSearchTerm?.length ? filteredData : data,
  })

  const getAllGroups = useCallback(async () => {
    if (company) {
      try {
        setIsError(false)
        setIsLoading(true)
        const res = await getProductLedgers(company?.value)
        console.log(res)
        res &&
          setData(
            res.map((_: any) => {
              return {
                ledgerId: _?.ledger_id,
                ledgerCode: _.ledger_code,
                parentLedger: _.parent_ledger,
                asOnDate: _.as_on_date,
                parentGroup: _.parent_group,
                subGroup: _.sub_group,

                transactionType: _.transaction_type,
                account_number: _.account_number,
                address: _.address,
                branch_name: _.branch_name,
                country: _.country,
                ifsc_code: _.ifsc_code,
                zipcode: _.postal_code,
                subLedger: _.sub_ledger,
                state: _.state,
                personName: _.person_name,
                isParentLedger: _.parent_ledger,
                openingBalance: _.opening_balance,
                currency: _.currency,
              }
            })
          )
      } catch (err) {
        setIsError(true)
      } finally {
        setIsLoading(false)
      }
    }
  }, [company])

  useEffect(() => {
    if (!createLedger) {
      getAllGroups()
      setEditLedger(undefined)
    }
  }, [getAllGroups, createLedger])

  return (
    <KTCard>
      <>
        <div className='card-header border-0 pt-6'>
          <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {/* end::Search */}
          </div>
          {/* begin::Card toolbar */}
          <div className='card-toolbar'>
            {/* begin::Group actions */}
            {
              <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                <button
                  type='button'
                  className='btn btn-primary me-2'
                  disabled={!data.length}
                  onClick={async () => {
                    toast.success('Download has been started successfully')
                    const response = await downloadPdf(data, company.value, financialYear.value)
                    const pdfData = response.data
                    const blob = new Blob([pdfData], {type: 'application/pdf'})
                    const url = window.URL.createObjectURL(blob)
                    const link: any = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'file.pdf') // The name of the downloaded file
                    document.body.appendChild(link)
                    link.click()

                    // // Clean up and revoke the object URL
                    link.parentNode.removeChild(link)
                    window.URL.revokeObjectURL(url)
                    // const doc = new jsPDF({
                    //   format: 'a2',
                    //   unit: 'px',
                    //   hotfixes: ['px_scaling'],
                    // })

                    // tableRef.current &&
                    //   doc.html(tableRef.current, {
                    //     callback: (doc) => {
                    //       const pageWidth = doc.internal.pageSize.getWidth()
                    //       const pageHeight = doc.internal.pageSize.getHeight()

                    //       const header = `${company.label} | ${financialYear.value}`
                    //       doc.setFontSize(26)
                    //       doc.setFont('Arial', 'italic', 600)
                    //       doc.text(header, (pageWidth - 300) / 2, 30)

                    //       const footer = `${new Date().getFullYear().toString()}© String Labs`
                    //       doc.setFontSize(20)
                    //       doc.text(footer, 30, pageHeight - 20 - 10)
                    //       const totalPages = doc.getNumberOfPages()

                    //       doc.deletePage(totalPages - 1)
                    //       doc.deletePage(totalPages - 2)
                    //       doc.deletePage(totalPages - 4)
                    //       doc.deletePage(totalPages - 3)

                    //       doc.save(
                    //         `Product Master - Ledgers - ${company.label}(${financialYear.value})`
                    //       )
                    //     },
                    //     margin: [80, 30, 30, 30],
                    //     autoPaging: 'text',
                    //   })
                  }}
                >
                  <KTIcon iconName='arrow-down' className='fs-2' />
                  Download
                </button>

                {/* begin::Add user */}
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => setCreateLedger(true)}
                >
                  <KTIcon iconName='plus' className='fs-2' />
                  Add Ledger
                </button>
                {/* end::Add user */}
              </div>
            }
            {/* end::Group actions */}
          </div>
          {/* end::Card toolbar */}
        </div>
        <KTCardBody className='py-4'>
          {!isLoading && (
            <div className='table-responsive'>
              <table
                ref={tableRef}
                id='pm_Ledger_table'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                {...getTableProps()}
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
                    {headers.map((column: ColumnInstance<ProductLedger>) => (
                      <CustomHeaderColumn key={column.id} column={column} />
                    ))}
                  </tr>
                </thead>
                <tbody className='text-gray-600 text-center fw-bold' {...getTableBodyProps()}>
                  {rows.length > 0 ? (
                    rows.map((row: Row<ProductLedger>, i) => {
                      prepareRow(row)
                      return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          No matching records found
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          {isLoading && <Loader isError={isError} />}
          {/* <Loader isError={true} /> */}
        </KTCardBody>
        <Modal
          className='modal-sticky modal-sticky-lg modal-sticky-bottom-right'
          role='dialog'
          data-backdrop='false'
          aria-hidden='true'
          tabIndex='-1'
          show={createLedger}
          animation={false}
          size='xl'
          contentClassName='top-0'
        >
          <CreateEditLedger setCreateLedger={setCreateLedger} editLedger={editLedger} />
        </Modal>
      </>
    </KTCard>
  )
}

type ColProps = {
  column: ColumnInstance<ProductLedger>
}

export const CustomHeaderColumn: React.FC<ColProps> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

type RowProps = {
  row: Row<ProductLedger>
}

const CustomRow: React.FC<RowProps> = ({row}) => (
  <tr {...row.getRowProps()} className='bg-hover-light'>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
        >
          {cell.render('Cell')}
        </td>
      )
    })}
  </tr>
)
