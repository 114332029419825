import {useCallback, useEffect, useState} from 'react'
import {UpdateExchangeRatesParams, getExchangeRates, updateExchangeRates} from './api'
import {useLayout} from '../../../../_metronic/layout/core'
import {formatNumber} from '../../../../_metronic/helpers'
import {toast} from 'react-hot-toast'
import {KTIcon} from '../../../../_metronic/helpers'

export const AddExchangeRate: React.FC<{
  setAddExchangeRate: React.Dispatch<React.SetStateAction<boolean>>
}> = ({setAddExchangeRate}) => {
  const {company, financialYear} = useLayout()
  const [isLoading, setIsLoading] = useState(false)
  const [exchangeDate, setExchangeDate] = useState(new Date().toISOString().split('T')[0])
  const [data, setData] = useState<UpdateExchangeRatesParams['updateExchangeRatesParams']>([])
  console.log(exchangeDate)
  const getData = useCallback(() => {
    if (company.value && financialYear.value) {
      getExchangeRates(company.value, financialYear.value, exchangeDate).then((res: any) => {
        console.log(res)
        const body = res?.map((c: any) => ({
          currencyCode: c.currencyCode,
          exchangeDate: c.exchangeDate,
          previousExchangeRate: c.exchangeRate,
          currentExchangeRate: c.newExchangeRate,
          companyId: c.companyId,
        }))
        body && setData(body)
      })
    }
  }, [company.value, exchangeDate, financialYear.value])

  useEffect(() => {
    getData()
  }, [getData])
  console.log(data)
  const handleSave = () => {
    setIsLoading(true)

    const body: UpdateExchangeRatesParams = {
      updateExchangeRatesParams: data.map((item) => {
        return {
          companyId: item.companyId,
          exchangeRateDate: item.exchangeRateDate,
          currencyCode: item.currencyCode,
          previousExchangeRate: item.previousExchangeRate,
          currentExchangeRate: item.currentExchangeRate,
        }
      }),
    }
    console.log(body)
    updateExchangeRates(exchangeDate, body)
      .then((res) => {
        res && toast.success(res?.detail)
        getData()
      })
      .catch((err) => toast.error(err || 'Something went wrong. Please try again later.'))
      .finally(() => setIsLoading(false))
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-bottom'>
        <div className='card-title m-0'>
          <div
            data-bs-toggle='tooltip'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            title='Go Back'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            style={{marginRight: '5px'}}
            onClick={() => setAddExchangeRate(false)}
          >
            <KTIcon iconName='arrow-left' className='fs-2' />
          </div>
          <h3 className='fw-bolder m-0'>Add New Exchange rate</h3>
        </div>
      </div>
      <div className='card-body '>
        <div className='row pb-3 border-bottom'>
          <div className='col-lg-3'>
            <label className='col-form-label fw-bold fs-6'>
              <span className='required'>Date for Exchange Rate</span>
            </label>{' '}
          </div>
          <div className='col-lg-3'>
            <input
              type='date'
              value={exchangeDate}
              onChange={(e) => setExchangeDate(e.target.value)}
              className='form-control'
              placeholder='Voucher Date'
            />
          </div>
        </div>
        <div className='row border-bottom p-3 text-start text-muted fw-bolder bg-light ps-4 fs-7 text-uppercase gs-0'>
          <div className='col-lg-2  '>Currency</div>
          <div className='col-lg-2  '>Exchange Date</div>
          <div className='col-lg-4  text-center'>Current Exchange Multiplier</div>
          <div className='col-lg-3  '>New Exchange Multiplier</div>
          <div className='col-lg-3' />
        </div>
        {data.map((curr: any, i) => (
          <div className='row border-bottom p-3 d-flex flex-center'>
            <div className='col-lg-2 fs-4 text-dark'>{curr.currencyCode}</div>
            <div className='col-lg-2 fs-4 text-dark'>{curr.exchangeDate}</div>
            <div className='col-lg-4 fs-4 text-dark text-center'>
              {!!curr.previousExchangeRate ? formatNumber(curr.previousExchangeRate) : '-'}
            </div>
            <div className='col-lg-3 fs-4 text-dark'>
              <input
                type='number'
                value={curr.currentExchangeRate === null ? 0 : curr.currentExchangeRate}
                className='form-control form-control-lg form-control-solid'
                onChange={(e) => {
                  const dat = [...data]
                  dat[i].currentExchangeRate = parseFloat(e.target.value)
                  setData(dat)
                }}
              />
            </div>
            <div className='col-lg-3' />
          </div>
        ))}
        <div className='d-flex flex-end p-3'>
          <button className='btn btn-secondary me-3' onClick={() => setAddExchangeRate(false)}>
            Cancel
          </button>
          <button className='btn btn-primary' onClick={handleSave}>
            {!isLoading ? 'Save Details' : 'Saving'}
          </button>
        </div>
      </div>
    </div>
  )
}
