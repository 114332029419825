import {useState} from 'react'
import {ProductGroup, createProductGroup, editProductGroup} from './api'
import {useLayout} from '../../../../_metronic/layout/core'
import {KTIcon} from '../../../../_metronic/helpers'
import toast from 'react-hot-toast'

const classificationMap: Record<string, string> = {
  'Current Liabilities': 'Liabilities',
  'Non-Current Liabilities': 'Liabilities',
  'Current Assets': 'Assets',
  'Non-Current Assets': 'Assets',
  'Direct Expenses': 'Expenses',
  'Indirect Expenses': 'Expenses',
  'Direct Income': 'Incomes',
  'Indirect Income': 'Incomes',
  Equity: 'Liabilities',
}

export const CreateEditGroup: React.FC<{
  setCreateGroup: React.Dispatch<React.SetStateAction<boolean>>
  editGroup?: ProductGroup
}> = ({setCreateGroup, editGroup}) => {
  const {company} = useLayout()
  const [loading, setLoading] = useState(false)
  const [subGroup, setSubGroup] = useState(editGroup?.subGroup ? editGroup.subGroup : '')
  const [parentGroup, setParentGroup] = useState(editGroup ? editGroup.parentGroup : '')

  const handleCreateNewGroup = async () => {
    setLoading(true)
    if (!editGroup?.groupId)
      createProductGroup({
        // group_id: editGroup?.dbId,
        parent_group: parentGroup,
        classification: classificationMap[parentGroup] || '',
        company_id: company?.value || '',
        sub_group: subGroup,
        is_parent_group: editGroup?.isParentGroup,
        is_default_group: editGroup?.isDefaultGroup,
      })
        .then((res) => {
          res?.detail && toast.success(res.detail)
          setSubGroup('')
          setParentGroup('')
        })
        .catch((err) => err && toast.error(err))
        .finally(() => setLoading(false))
    else
      editProductGroup({
        group_id: editGroup?.groupId,
        parent_group: parentGroup,
        classification: classificationMap[parentGroup] || '',
        company_id: company?.value || '',
        sub_group: subGroup,
        is_parent_group: editGroup?.isParentGroup,
        is_default_group: editGroup?.isDefaultGroup,
      })
        .then((res) => {
          res?.detail && toast.success(res.detail)
          setSubGroup('')
          setParentGroup('')
        })
        .catch((err) => err && toast.error(err))
        .finally(() => setLoading(false))
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <div
            data-bs-toggle='tooltip'
            data-bs-trigger='hover'
            data-bs-dismiss-='click'
            title='Go Back'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            style={{marginRight: '5px'}}
            onClick={() => setCreateGroup(false)}
          >
            <KTIcon iconName='arrow-left' className='fs-2' />
          </div>
          <h3 className='fw-bolder m-0'>{!!editGroup ? 'Update Group' : 'Add New Group'}</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Parent Group</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <select
                className='form-select form-select-solid form-select-lg fw-bold'
                value={parentGroup}
                onChange={(e) => setParentGroup(e.currentTarget.value)}
              >
                <option value=''>Select a Parent Group...</option>
                <option value='Current Liabilities'>Current Liabilities</option>
                <option value='Current Assets'>Current Assets</option>
                <option value='Non-Current Assets'>Non-Current Assets</option>
                <option value='Non-Current Liabilities'>Non-Current Liabilities</option>
                <option value='Direct Expenses'>Direct Expenses</option>
                <option value='Indirect Expenses'>Indirect Expenses</option>
                <option value='Direct Income'>Direct Income</option>
                <option value='Indirect Income'>Indirect Income</option>
                <option value='Equity'>Equity</option>
              </select>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Group Name</label>

            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Group name'
                value={subGroup}
                onChange={(e) => setSubGroup(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button
            className='btn btn-secondary'
            style={{marginRight: '10px'}}
            onClick={() => setCreateGroup(false)}
          >
            Cancel
          </button>
          <button
            onClick={handleCreateNewGroup}
            className='btn btn-primary ml-2'
            disabled={loading || !parentGroup.length || !subGroup.length}
          >
            {!loading && !!editGroup ? 'Save Group' : 'Add Group'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}
