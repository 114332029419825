import axios, {AxiosResponse} from 'axios'
import {ID} from '../../../../../../_metronic/helpers'
import {CompaniesQueryResponse, Company, CreateCompanyBody} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const COMPANY_URL = `${API_URL}/company`
const GET_ALL_COMPANY_URL = `${API_URL}/company/all`

const getCompanies = async (query: string): Promise<CompaniesQueryResponse> => {
  return await axios
    .get(`${GET_ALL_COMPANY_URL}?${query}`)
    .then((d: AxiosResponse<CompaniesQueryResponse>) => d.data)
}

const getCompanyById = async (id: ID): Promise<Company | undefined> => {
  return await axios
    .get(`${COMPANY_URL}/${id}`)
    .then((response: AxiosResponse<Company>) => response.data)
    .then((response: Company) => response)
}

const createCompany = async (company: any): Promise<{detail: string}> => {
  return await axios
    .post(`${COMPANY_URL}/add`, company)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

const updateCompany = async (company: any): Promise<{detail: string}> => {
  return await axios
    .post(`${COMPANY_URL}/update`, company)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

const getDisableCompanyFY = async (id: ID, fy: string): Promise<{detail: string}> => {
  return await axios
    .get(`${COMPANY_URL}/disable/${id}?fy=${fy}`)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

const getAddNewFY = async (
  id: ID,
  currency: string,
  exchangeRate: number
): Promise<{detail: string}> => {
  return await axios
    .get(`${COMPANY_URL}/create-new-fy/${id}?currency=${currency}&exchange_rate=${exchangeRate}`)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

const deleteUser = async (userId: ID): Promise<void> => {
  return await axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = async (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getCompanies,
  deleteUser,
  deleteSelectedUsers,
  getCompanyById,
  getDisableCompanyFY,
  getAddNewFY,
  createCompany,
  updateCompany,
}
