import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_THEME_API_URL

export interface ProductVoucher {
  voucherId?: number
  voucherType: string
  voucherName: string
  isEditable?: Boolean
}

export const getProductVouchers = async (
  companyId: string
): Promise<ProductVoucher[] | undefined> => {
  return await axios
    .get(`${API_URL}/product-master/voucher/${companyId}`)
    .then((response: AxiosResponse<ProductVoucher[]>) => response.data)
}

export interface CreateProductVoucherBody {
  voucher_id?: number
  company_id?: number
  voucher_type?: string
  voucher_name?: string
  is_editable?: boolean
}

export const createProductVoucher = async (
  body: CreateProductVoucherBody
): Promise<{detail: string} | undefined> => {
  return await axios
    .post(`${API_URL}/product-master/voucher/add`, body)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}
export const editProductVoucher = async (
  body: CreateProductVoucherBody
): Promise<{detail: string} | undefined> => {
  return await axios
    .post(`${API_URL}/product-master/voucher/update`, body)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

export const deleteProductVoucher = async (
  companyId: any,
  voucherId: string | null
): Promise<{detail: string} | undefined> => {
  return await axios
    .delete(`${API_URL}/product-master/voucher/${companyId}/${voucherId}`)
    .then((response: AxiosResponse<{detail: string}>) => response.data)
    .then((response: {detail: string}) => response)
}

export const downloadPdf = async (
  records: ProductVoucher[],
  companyId: string,
  fyYear: string
): Promise<any | undefined> => {
  return await axios.post<any>(`${API_URL}/pdfDownload`, {
    records,
    companyId,
    fyYear,
  })
}
